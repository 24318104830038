import React from 'react';
import { EmptyState, useTheme } from '@drivekyte/web-components';
import { useRouter } from 'next/router';
import Page from '@/components/page';
import useTranslation from '@/hooks/use-translation';

const Error404 = () => {
  const [theme] = useTheme();
  const { t } = useTranslation('global.errors.404');
  const router = useRouter();

  const handleCtaPress = () => router.push('/');

  return (
    <Page
      title={t('title')}
      top={{
        mobile: theme.spacings.small * 6,
        web: theme.spacings.small * 8,
      }}
    >
      <EmptyState
        ctaText={t('empty-state.cta')}
        descriptionText={t('empty-state.description')}
        illustrationName="trips-empty-state"
        onCtaPress={handleCtaPress}
        titleText={t('empty-state.title')}
      />
    </Page>
  );
};

export default Error404;
